// master version
import React, { Component, useState, useEffect, useMemo } from "react";
import decompressData from "../../utils/decompressData";
import pako from "pako";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import axios from "axios";
import {
  CheckIcon,
  SelectorIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import DonationDatePicker from "./DonationDatePicker";
import { Popover, Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import fetchTransactionData from "../../utils/FetchTransactionData";
import TransactionSlideover from "../slideovers/TransactionSlideover";
import ContentLoader from "react-content-loader";
import {
  formatTimestamp,
  formatTimestampToDate,
  formatTimestampToLocal,
} from "../../utils/formatTimestamp";
import {
  fundsToCSVString,
  ticketsToCSVString,
  customInputsToCSVString,
} from "../../utils/CSVArrayFormatter";
import {
  queryTransactions,
  queryTransactionsTopForms,
  queryTransactionsTopFunds,
  queryTransactionsTopNames,
  queryTransactionsTopEmails,
} from "../../utils/RocksetQueries/TransactionQueries";
import CsvDownloader from "react-csv-downloader";
import ErrorAlert from "../alerts/ErrorAlert";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const columns = [
  { width: "8%" },
  { width: "8%" },
  { width: "25%" },
  { width: "15%" },
  { width: "10%" },
  { width: "5%" },
  { width: "8%" },
  { width: "8%" },
  { width: "13%" },
];

const numberOfRows = 20;
const rowHeight = 22;
const spaceBetweenRows = 20;
const totalHeight = numberOfRows * (rowHeight + spaceBetweenRows);
const numberOfGaps = columns.length - 1; // One less gap than the number of columns
const totalSpacingPercent = 8; // Total percentage of width allocated for spacing
const singleSpacingPercent = totalSpacingPercent / numberOfGaps; // Percentage of each gap

// Adjusted column widths to account for the spacing
const adjustedColumns = columns.map((col, index) => {
  const originalWidth = parseFloat(col.width);
  const adjustedWidth = (
    originalWidth -
    (singleSpacingPercent * (columns.length - 1)) / columns.length
  ).toFixed(2);
  return { ...col, width: `${adjustedWidth}%` };
});

const calculateXPosition = (colIndex, spacingPercent) => {
  // No offset for the first column
  if (colIndex === 0) return 1;

  // Calculate the sum of all previous column widths
  const widthSum = adjustedColumns
    .slice(0, colIndex)
    .reduce((acc, col) => acc + parseFloat(col.width), 0);

  // Calculate the total spacing to add based on the column index
  const totalSpacing = (colIndex - 1) * spacingPercent; // -1 because no space before first column

  // Sum the width and spacing to get the starting x position
  return widthSum + totalSpacing;
};

const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
let today = new Date();
// increment today forward by 1 day
today.setDate(today.getDate() + 1);
let tomorrow = new Date();
tomorrow.setDate(today.getDate() - 6000);
const startTimerange =
  tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
  " - " +
  today.toLocaleDateString("en-US", DATE_OPTIONS);

const EXPORT_LIMIT = 999999999;

export class DonationsTable extends Component {
  constructor(props) {
    super(props);
    this.csvLinkRef = React.createRef();

    this.state = {
      jwtToken: "",
      donationList: [],
      importedTransaction: false,
      start: 0,
      end: 0,
      total: 0,
      totalDonation: 0,
      avgDonation: 0,
      totalDonors: 0,
      duplicate: 0,
      grossAmountList: [],
      totalAmountList: [],
      showFilter: false,
      filteredFund: "",
      filteredFundList: [],
      filteredDonor: "",
      filteredDonorList: [],
      filteredEmailList: [],
      filteredForm: "",
      filteredFormList: [],
      filteredPaymentType: [],
      filteredState: [],
      filteredFeeCovered: "",
      filteredOperator: "=",
      filteredAmount: 0, // This is the actual "set" value that we filter on. We only set this when the box becomes out of focus
      filteredAmountDisplayed: "", // this value is needed because we need to populate text box
      filteredAmountSet: false, // This is to determine if the user ever set the value initially
      filteredKeywordSet: false, // This is to determine if the user ever set the value initially
      timerange: startTimerange,
      whoIsLoggedIn: "",
      listOfAllPossibleFunds: [],
      whoIsLoggedIn: "",
      showDateFilter: true,
      showKeywordFilter: false,
      showAmountFilter: false,
      showMethodFilter: false,
      showStatusFilter: false,
      resetFilter: false,
      keywordSearchTerm: "",
      amountSpecific: "",
      amountNoMoreThan: "",
      amountAtLeast: "",
      filteredList: [],
      loading: true,
      originalList: [],
      transactionMap: {},
      recentFundsText: "Recent Funds",
      recentDonorsText: "Recent Contacts",
      recentEmailsText: "Recent Emails",
      recentFormsText: "Recent Forms",
      savedFilterIndicatorDate: false,
      savedFilterIndicatorKeywordFund: false,
      savedFilterIndicatorKeywordDonor: false,
      savedFilterIndicatorKeywordSearch: false,
      showIndicators: false,
      savedFilterIndicatorAmount: false,
      savedFilterIndicatorMethod: false,
      savedFilterIndicatorStatus: false,
      isHovered: false,
      hoveredIndex: null,
      open: false,
      selectedTransaction: null,
      keywordScroll: false,
      merchants: [],
      totalCount: 0,
      totalGrossAmount: 0,
      listOfAllPossibleFunds: [],
      listOfAllPossibleDonors: [],
      listOfAllPossibleEmails: [],
      listOfAllPossibleForms: [],
      csvLoading: false,
      nextLoading: false,
      showAmountFilterError: false,
      amountFilterErrorMessage: "",
      amountIndicatorValue: "",
      exportList: [],
    };
  }

  setShowAmountFilterError = (toggle) => {
    this.setState({ showAmountFilterError: toggle });
  };

  handleKeywordScroll = (toggle) => {
    this.setState({ keywordScroll: toggle });
  };

  setOpen = (toggle) => {
    this.setState({ open: toggle });
  };

  openSlideOver = (transactionID) => {
    const { transactionMap } = this.state;
    this.setOpen(true);
    const selectedTransaction = transactionMap[transactionID];
    console.log("selectedTransaction", selectedTransaction);
    this.setState({
      selectedTransaction: selectedTransaction,
    });
  };

  handleHover = (index) => {
    this.setState({ hoveredIndex: index });
  };

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  onResetFilter = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredKeywordSet: true,
        resetFilter: true,
        showDateFilter: true,
        showKeywordFilter: false,
        showAmountFilter: false,
        showMethodFilter: false,
        showStatusFilter: false,
        filteredFund: "",
        filteredFundList: [],
        filteredDonor: "",
        filteredDonorList: [],
        filteredEmailList: [],
        filteredForm: "",
        filteredFormList: [],
        filteredPaymentType: [],
        filteredState: [],
        keywordSearchTerm: "",
        filteredFeeCovered: "",
        filteredOperator: "=",
        importedTransaction: false,
        amountSpecific: "",
        amountNoMoreThan: "",
        amountAtLeast: "",
        showFilter: false,
        timerange: startTimerange,
        // loading: true,
        savedFilterIndicatorDate: false,
        savedFilterIndicatorKeywordFund: false,
        savedFilterIndicatorKeywordDonor: false,
        savedFilterIndicatorKeywordSearch: false,
        showIndicators: false,

        savedFilterIndicatorAmount: false,
        savedFilterIndicatorMethod: false,
        savedFilterIndicatorStatus: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onSavedIndicatorKeywordClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredFund: "",
        filteredFundList: [],
        filteredDonor: "",
        filteredDonorList: [],
        filteredEmailList: [],
        filteredForm: "",
        filteredFormList: [],
        keywordSearchTerm: "",
        // loading: true,

        savedFilterIndicatorKeywordFund: false,
        savedFilterIndicatorKeywordDonor: false,
        savedFilterIndicatorKeywordSearch: false,
      },
      () => {
        this.updateData();
      }
    );
  };

  onSavedIndicatorDateClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        timerange: startTimerange,
        // loading: true,
        savedFilterIndicatorDate: false,
        //close the menu
      },
      () => {
        this.updateData();
      }
    );
  };

  onSavedIndicatorAmountClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        amountSpecific: "",
        amountNoMoreThan: "",
        amountAtLeast: "",
        // loading: true,
        savedFilterIndicatorAmount: false,
      },
      () => {
        this.updateData();
      }
    );
  };
  onSavedIndicatorMethodClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredPaymentType: [],
        // loading: true,
        savedFilterIndicatorMethod: false,
      },
      () => {
        this.updateData();
      }
    );
  };
  onSavedIndicatorStatusClick = () => {
    const { originalList } = this.state;
    this.setState(
      {
        filteredState: [],
        // loading: true,
        savedFilterIndicatorStatus: false,
      },
      () => {
        this.updateData();
      }
    );
  };
  onFilterDateFilter = () => {
    if (this.state.showDateFilter === true) {
    } else {
      this.setState({
        showDateFilter: true,
        showKeywordFilter: false,
        showAmountFilter: false,
        showMethodFilter: false,
        showStatusFilter: false,
      });
    }
  };
  onFilterKeywordFilter = () => {
    this.setState({
      showKeywordFilter: true,
      showAmountFilter: false,
      showMethodFilter: false,
      showStatusFilter: false,
      showDateFilter: false,
    });
  };
  onFilterAmountFilter = () => {
    this.setState({
      showAmountFilter: true,
      showKeywordFilter: false,
      showMethodFilter: false,
      showStatusFilter: false,
      showDateFilter: false,
    });
  };
  onFilterMethodFilter = () => {
    this.setState({
      showMethodFilter: true,
      showAmountFilter: false,
      showKeywordFilter: false,
      showStatusFilter: false,
      showDateFilter: false,
    });
  };
  onFilterStatusFilter = () => {
    this.setState({
      showStatusFilter: true,
      showAmountFilter: false,
      showMethodFilter: false,
      showKeywordFilter: false,
      showDateFilter: false,
    });
  };
  onFilterClickHandler = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  onFilteredAmountDisplayedClickHandler = (e) => {
    e.preventDefault();
    this.setState(
      {
        filteredAmountDisplayed: e.target.value,
      },
      () => this.updateFilter()
    );
  };

  onFilteredAmountClickHandler = (e) => {
    e.preventDefault();
    this.setState(
      {
        filteredAmount: +e.target.value,
        filteredAmountSet: true,
      },
      () => this.updateFilter()
    );
  };

  onFilteredOperatorClickHandler = (e) => {
    e.preventDefault();
    this.setState(
      {
        filteredOperator: e.target.value,
      },
      () => this.updateFilter()
    );
  };

  onKeywordSearchApply = async (e) => {
    e.preventDefault();
    const { keywordSearchTerm } = this.state;
    if (keywordSearchTerm.length === 0) {
      this.setState(
        {
          recentFundsText: "Recent Funds",
          recentDonorsText: "Recent Contacts",
          recentEmailsText: "Recent Emails",
          recentFormsText: "Recent Forms",
          savedFilterIndicatorKeywordSearch: false,
        },
        () => this.updateFilter()
      );
    }
    if (keywordSearchTerm.length > 0) {
      this.setState(
        {
          recentFundsText: "Funds",
          recentDonorsText: "Contacts",
          recentEmailsText: "Emails",
          recentFormsText: "Forms",
          savedFilterIndicatorKeywordSearch: true,
        },
        () => this.updateFilter()
      );
    }
  };

  onKeywordSearchFilter = async (e) => {
    e.preventDefault();
    this.setState({
      keywordSearchTerm: e.target.value,
    });
  };

  onFilteredFundClickHandler = (e) => {
    //    e.preventDefault();
    let newFundValue = this.state.filteredFundList;
    if (newFundValue.includes(e.target.value)) {
      newFundValue.splice(newFundValue.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredFundList: newFundValue,
          savedFilterIndicatorKeywordFund: false,
        },
        () => this.updateData()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value;
      this.setState(
        {
          filteredFundList: newFundValue,
          savedFilterIndicatorKeywordFund: true,
        },
        () => this.updateData()
      );
    }
  };

  onFilteredFormClickHandler = (e) => {
    //    e.preventDefault();
    let formValue = this.state.filteredFormList;
    if (formValue.includes(e.target.value)) {
      formValue.splice(formValue.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredFormList: formValue,
          savedFilterIndicatorKeywordFund: false,
        },
        () => this.updateData()
      );
    } else {
      formValue[formValue.length] = e.target.value;
      this.setState(
        {
          filteredFormList: formValue,
          savedFilterIndicatorKeywordFund: true,
        },
        () => this.updateData()
      );
    }
  };

  onFilteredFundDonorClickHandler = (e) => {
    //    e.preventDefault();
    let newFundValue = this.state.filteredDonorList;
    if (newFundValue.includes(e.target.value)) {
      newFundValue.splice(newFundValue.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredDonorList: newFundValue,
          savedFilterIndicatorKeywordDonor: false,
        },
        () => this.updateData()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value;
      this.setState(
        {
          filteredDonorList: newFundValue,
          savedFilterIndicatorKeywordDonor: true,
        },
        () => this.updateData()
      );
    }
  };

  onFilteredEmailClickHandler = (e) => {
    //    e.preventDefault();
    let newEmailList = this.state.filteredEmailList;
    if (newEmailList.includes(e.target.value)) {
      newEmailList.splice(newEmailList.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredEmailList: newEmailList,
        },
        () => this.updateData()
      );
    } else {
      newEmailList[newEmailList.length] = e.target.value;
      this.setState(
        {
          filteredEmailList: newEmailList,
        },
        () => this.updateData()
      );
    }
  };

  onFilteredPaymentTypeClickHandler = (e) => {
    //   e.preventDefault();
    let newFundValue = this.state.filteredPaymentType;
    if (newFundValue.includes(e.target.value)) {
      newFundValue.splice(newFundValue.indexOf(e.target.value), 1);
      this.setState(
        {
          filteredPaymentType: newFundValue,
          savedFilterIndicatorMethod: false,
        },
        () => this.updateData()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value;
      this.setState(
        {
          filteredPaymentType: newFundValue,
          savedFilterIndicatorMethod: true,
        },
        () => this.updateData()
      );
    }
  };
  onFilteredStateClickHandler = (e) => {
    //   e.preventDefault();
    const { filteredState } = this.state;
    let newFundValue = this.state.filteredState;
    // if (e.target.value === "Refunded") {
    //   console.log("Refunded option selected");

    //   const hasPartial = newFundValue.includes("Partial Refund");
    //   const hasFull = newFundValue.includes("Full Refund");

    //   console.log(
    //     "Has Partial Refund:",
    //     hasPartial,
    //     "Has Full Refund:",
    //     hasFull
    //   );

    //   if (hasPartial || hasFull) {
    //     console.log(
    //       "Removing Partial Refund and Full Refund from newFundValue"
    //     );
    //     newFundValue = newFundValue.filter((val) => val !== "Refunded");
    //   } else {
    //     console.log("Adding Partial Refund and Full Refund to newFundValue");
    //     newFundValue.push("Refunded");
    //   }
    // }
    // if (e.target.value === "Disputed") {
    //   const hasOpen = newFundValue.includes("Disputed Open");
    //   const hasClosed = newFundValue.includes("Disputed Closed");

    //   if (hasOpen || hasClosed) {
    //     console.log(
    //       "Removing Disputed Open and Disputed Closed from newFundValue"
    //     );
    //     newFundValue = newFundValue.filter((val) => val !== "Disputed");
    //   } else {
    //     console.log("Adding Disputed Open and Disputed Closed to newFundValue");
    //     newFundValue.push("Disputed");
    //   }
    // }
    if (newFundValue.includes(e.target.value)) {
      newFundValue.splice(newFundValue.indexOf(e.target.value), 1);
      let filterIndicatorStatus = true;
      // only set to false when all status filters are cleared
      if (filteredState.length == 0) {
        filterIndicatorStatus = false;
      }
      this.setState(
        {
          filteredState: newFundValue,
          savedFilterIndicatorStatus: filterIndicatorStatus,
        },
        () => this.updateData()
      );
    } else {
      newFundValue[newFundValue.length] = e.target.value;
      this.setState(
        {
          filteredState: newFundValue,
          savedFilterIndicatorStatus: true,
        },
        () => this.updateData()
      );
    }
  };

  amountFilterChange = (e) => {
    const { amountAtLeast, amountSpecific, amountNoMoreThan } = this.state;
    e.preventDefault();
    let value = e.target.value;
    let savedFilterNewValue = false;
    value = value.replace(/[^0-9.].*/g, "");

    //allows only 1 decimal
    let decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount == 2) {
      let index = value.lastIndexOf(".");
      value = value.substr(0, index);
    }
    value = value.replace(/(\.\d{2})\d{1,}/g, "$1");

    if (value === ".") {
      value = "0.";
    }
    // console.log("at least", amountAtLeast, "no more", amountNoMoreThan, "spesific", amountSpecific)
    if (
      (amountAtLeast.length !== 0 || amountSpecific.length !== 0,
      amountNoMoreThan.length !== 0)
    ) {
      savedFilterNewValue = true;
    }
    this.setState({
      [e.target.name]: value,
    });
  };

  // onFilteredFeeCoveredClickHandler = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     filteredFeeCovered: e.target.text,
  //   });
  // };

  async componentDidMount() {
    const data = await Auth.currentSession();
    // grab groups from cognito
    const groups = data["idToken"]["payload"]["cognito:groups"];
    // replace the - with a space for every value in the array
    const groupsWithSpaces = groups.map((group) => group.replace(/-/g, " "));
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
        merchants: groupsWithSpaces,
      },
      () => this.updateData()
    );
    // console.log("queryTransactions", await queryTransactions());
  }

  componentDidUpdate(prevProps, prevState) {
    // check if donationList updated
    if (
      this.state.exportList !== prevState.exportList &&
      this.state.csvLoading === true
    ) {
      this.setState({ csvLoading: false }, () => {
        // click the CSV link
        this.csvLinkRef.current.link.click();
        // wait 1 second
        delay(1000).then(() => {
          // call update data with the current end value
          this.setState({ exportList: [] });
        });
      });
    }
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  setDateTimeRange = (timerange) => {
    this.setState(
      {
        timerange: timerange,
        savedFilterIndicatorDate: true,
      },
      () => this.updateData()
    );
  };

  updateFilter = async () => {
    const {
      filteredState,
      filteredFeeCovered,
      resetFilter,
      filteredFundList,
      filteredDonorList,
      keywordSearchTerm,
      savedFilterIndicatorDate,
      savedFilterIndicatorAmount,
      savedFilterIndicatorKeyword,
      savedFilterIndicatorMethod,
      filteredPaymentType,
      amountAtLeast,
      amountSpecific,
      amountNoMoreThan,
      donationList,
      loading,
      merchants,
      timerange,
      jwtToken,
    } = this.state;
    let grossAmountList = [];
    let totalAmountList = [];
    let setOfFunds = new Set();
    let setOfDonors = new Set();
    let setOfForms = new Set();

    //let filteredList = res
    let filteredList = donationList;
    // if (filteredPaymentType.length !== 0) {
    //   let filteredPaymentTypeHolder = [];
    //   for (var i = 0; i < filteredPaymentType.length; i++) {
    //     filteredPaymentTypeHolder[i] = filteredPaymentType[i]
    //       .replaceAll(" ", "_")
    //       .toUpperCase();
    //   }
    //   filteredList = filteredList.filter((t) =>
    //     filteredPaymentTypeHolder.includes(t.paymentMethod)
    //   );
    //   //      filteredList = filteredList.filter(t => t.paymentMethod === filteredPaymentTypeHolder.replace(' ', '_').toUpperCase());
    // }
    if (filteredFeeCovered !== "") {
      filteredList = filteredList.filter(
        (t) => t.coverFees === filteredFeeCovered
      );
    }
    // if (filteredState.length !== 0) {
    //   filteredList = filteredList.filter((t) =>
    //     filteredState.includes(t.state)
    //   );
    // }
    let atLeastOneAmountPresent = false;
    if (amountAtLeast !== "") {
      atLeastOneAmountPresent = true;
      // let parsedAmountFilter = parseFloat(amountAtLeast);
      // filteredList = filteredList.filter(
      //   (t) => t.totalAmount >= parsedAmountFilter
      // );
      this.setState({
        savedFilterIndicatorAmount: true,
      });
    }
    if (amountNoMoreThan !== "") {
      atLeastOneAmountPresent = true;
      // let parsedAmountFilter = parseFloat(amountNoMoreThan);
      // filteredList = filteredList.filter(
      //   (t) => t.totalAmount <= parsedAmountFilter
      // );
      this.setState({
        savedFilterIndicatorAmount: true,
      });
    }
    if (amountSpecific !== "") {
      atLeastOneAmountPresent = true;
      // let parsedAmountFilter = parseFloat(amountSpecific);
      // filteredList = filteredList.filter(
      //   (t) => t.totalAmount == parsedAmountFilter
      // );
      this.setState({
        savedFilterIndicatorAmount: true,
      });
    }
    if (!atLeastOneAmountPresent) {
      this.setState({
        savedFilterIndicatorAmount: false,
      });
    }

    const [
      listOfAllPossibleForms,
      listOfAllPossibleFunds,
      listOfAllPossibleDonors,
      listOfAllPossibleEmails,
    ] = await Promise.all([
      queryTransactionsTopForms(
        jwtToken,
        merchants,
        timerange,
        amountAtLeast,
        amountNoMoreThan,
        amountSpecific,
        filteredPaymentType,
        filteredState,
        keywordSearchTerm
      ),
      queryTransactionsTopFunds(
        jwtToken,
        merchants,
        timerange,
        amountAtLeast,
        amountNoMoreThan,
        amountSpecific,
        filteredPaymentType,
        filteredState,
        keywordSearchTerm
      ),
      queryTransactionsTopNames(
        jwtToken,
        merchants,
        timerange,
        amountAtLeast,
        amountNoMoreThan,
        amountSpecific,
        filteredPaymentType,
        filteredState,
        keywordSearchTerm
      ),
      queryTransactionsTopEmails(
        jwtToken,
        merchants,
        timerange,
        amountAtLeast,
        amountNoMoreThan,
        amountSpecific,
        filteredPaymentType,
        filteredState,
        keywordSearchTerm
      ),
    ]);

    this.setState({
      filteredList: filteredList,
      totalAmountList: totalAmountList,
      grossAmountList: grossAmountList,
      listOfAllPossibleEmails: listOfAllPossibleEmails,
      listOfAllPossibleDonors: listOfAllPossibleDonors,
      listOfAllPossibleForms: listOfAllPossibleForms,
      listOfAllPossibleFunds: listOfAllPossibleFunds,
      loading: false,
    });
  };

  updateData = async (newLimit) => {
    // this.setState({ loading: true });
    const {
      merchants,
      timerange,
      amountAtLeast,
      amountNoMoreThan,
      amountSpecific,
      filteredPaymentType,
      filteredState,
      filteredDonorList,
      filteredEmailList,
      filteredFormList,
      filteredFundList,
      jwtToken,
    } = this.state;
    console.log("timerange", timerange);
    let res = [];
    if (newLimit == EXPORT_LIMIT) {
      let cursor = 0;
      let searchAfter = undefined;
      while (cursor < this.state.totalCount) {
        let limit = 1000;
        if (this.state.totalCount - cursor < 1000) {
          limit = this.state.totalCount - cursor;
        }
        const newRes = await queryTransactions(
          jwtToken,
          merchants,
          timerange,
          amountAtLeast,
          amountNoMoreThan,
          amountSpecific,
          filteredPaymentType,
          filteredState,
          filteredDonorList,
          filteredEmailList,
          filteredFormList,
          filteredFundList,
          limit,
          searchAfter
        );
        // extend res with newRes
        res = res.concat(newRes);
        cursor += newRes.length;
        if (newRes.length === 0) {
          break;
        }
        searchAfter = newRes[0].searchAfter;
      }
    } else {
      res = await queryTransactions(
        jwtToken,
        merchants,
        timerange,
        amountAtLeast,
        amountNoMoreThan,
        amountSpecific,
        filteredPaymentType,
        filteredState,
        filteredDonorList,
        filteredEmailList,
        filteredFormList,
        filteredFundList,
        newLimit ? newLimit : 20
      );
    }
    let totalGrossAmount = 0;
    let totalCount = 0;
    if (res.length !== 0) {
      console.log("res[0]", res[0]);
      totalGrossAmount = res[0].totalGrossAmount ?? 0;
      totalCount = res[0].totalCount;
    }
    let end = 20;
    if (res.length < 20) {
      end = res.length;
    }
    const transactionMap = {};
    for (let index = 0; index < res.length; index++) {
      // map transaction id to transaction object
      transactionMap[res[index].transactionID] = res[index];

      // const customInputsData = res[index].customInputs.map((customInput) => {
      //   const requiredMessage = customInput.required ? "" : " (not required)";
      //   const value =
      //     customInput.answers.length !== 0 ? customInput.answers[0] : "<EMPTY>";
      //   return customInput.title + requiredMessage + ": " + value;
      // });
      // if (customInputsData.length !== 0) {
      //   res[index].customInputs = JSON.stringify(customInputsData);
      //   // take out the first character and last two characters of the string
      //   res[index].customInputs = res[index].customInputs.substring(
      //     1,
      //     res[index].customInputs.length - 2
      //   );
      // }

      if (res[index].donationFrequency == "monthlyAutomated") {
        res[index].donationFrequency = "Monthly Donation";
      }
      if (res[index].donationFrequency == "monthlySignup") {
        res[index].donationFrequency = "New Monthly Donation";
      }
      if (res[index].donationFrequency == "weeklyAutomated") {
        res[index].donationFrequency = "Weekly Donation";
      }
      if (res[index].donationFrequency == "weeklySignup") {
        res[index].donationFrequency = "New Weekly Donation";
      }
      if (res[index].donationFrequency == "annuallyAutomated") {
        res[index].donationFrequency = "Yearly Donation";
      }
      if (res[index].donationFrequency == "annuallySignup") {
        res[index].donationFrequency = "New Yearly Donation";
      }
      if (res[index].donationFrequency == "dailyAutomated") {
        res[index].donationFrequency = "Daily Donation";
      }
      if (res[index].donationFrequency == "dailySignup") {
        res[index].donationFrequency = "New Daily Donation";
      }
      if (res[index].donationFrequency == "oneTime") {
        res[index].donationFrequency = "One-Time Donation";
      }
      if (res[index].coverFees === false) {
        res[index].coverFees = "No";
      } else {
        res[index].coverFees = "Yes";
      }

      if (res[index].state === "SUCCEEDED") {
        res[index].state = "Success";
      } else if (res[index].state === "PENDING") {
        res[index].state = "Pending";
      } else if (res[index].state === "REFUNDED") {
        res[index].state = "Refunded";
      } else if (
        [
          // "FUND_REFUND",
          // "TICKET_REFUND",
          // "FEE_REFUND",
          "PARTIAL_REFUND",
        ].includes(res[index].state)
      ) {
        res[index].state = "Partial Refund";
      } else if (res[index].state === "FULL_REFUND") {
        res[index].state = "Full Refund";
      }
      // if (
      //   res[index].state !== "Success" &&
      //   res[index].state !== "Pending" &&
      //   !res[index].state.includes("Refund")
      // )
      else if (res[index].state.includes("DISPUTED")) {
        res[index].state = "Disputed";
      } else {
        console.log("res[index].state", res[index].state);
        res[index].state = "Failed";
      }
      //paymentinstrument id holds the totalamountlist variable
      res[index].totalAmount = (res[index].amount - res[index].fees).toFixed(2);
      if (res[index].coverFees === "Yes") {
        res[index].grossAmount = res[index].totalAmount;
      } else {
        res[index].grossAmount = res[index].amount;
      }

      // ---- REFUND LOGIC ----
      // // grab refunded data if it exists
      // const refundedData = res[index].refunded ?? [];
      // // sum up the refundAmount
      // const totalRefundedAmount = refundedData.reduce(
      //   (acc, val) => acc + val.refundAmount,
      //   0
      // );
      // // set the net refund amount
      // res[index].totalRefundedAmount = totalRefundedAmount;

      // set refundAmount to the latest entry in refunded
      // set refundReason to the latest entry in refunded
      const refundedData = res[index].refunded ?? [];
      if (refundedData.length > 0) {
        const latestRefund = refundedData[refundedData.length - 1];
        res[index].refundAmount = latestRefund.refundAmount;
        res[index].refundReason = latestRefund.reason;
      }
    }
    let originalList = this.state.originalList;
    if (originalList.length === 0) {
      originalList = res;
    }
    console.log("finished updating data");

    if (newLimit == EXPORT_LIMIT) {
      this.setState({ exportList: res });
    } else {
      const updateStates = {
        donationList: res,
        total: totalCount,
        originalList: originalList,
        loading: false,
        transactionMap: transactionMap,
        totalCount: totalCount,
        totalGrossAmount: totalGrossAmount,
      };

      if (!newLimit) {
        updateStates.start = 0;
        updateStates.end = end;
      }

      this.setState(updateStates, () => this.updateFilter());
    }
  };

  goNext = (e) => {
    e.preventDefault();
    const { start, end, totalCount, donationList } = this.state;

    if (end === totalCount) {
      return;
    }

    this.setState({ nextLoading: true });

    let newEnd = end + Math.min(20, totalCount - end);
    console.log(
      "newEnd",
      newEnd,
      "end",
      end,
      "totalCount",
      totalCount,
      "donationList",
      donationList.length
    );
    if (newEnd > donationList.length) {
      this.updateData(end + 20).then(() => {
        console.log("newEnd & start", newEnd, start + 20);
        this.setState({
          start: start + 20,
          end: newEnd,
          nextLoading: false,
        });
      });
    } else {
      console.log("newEnd & start", newEnd, start + 20);
      this.setState({
        start: start + 20,
        end: newEnd,
        nextLoading: false,
      });
    }
  };

  goPrev = (e) => {
    e.preventDefault();
    const { start, end, total } = this.state;
    if (start !== 0) {
      this.setState({
        start: Math.max(start - 20, 0),
        end: start,
      });
    }
  };

  setSelectedTransaction = (transaction) => {
    this.setState({ selectedTransaction: transaction });
  };

  headers1 = [
    { label: "Date", key: "timestamp" },
    { label: "Short Date", key: "date" },
    { label: "CharityStack Donation ID", key: "transactionID" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Fund", key: "fund" },
    { label: "Funds", key: "funds" },
    { label: "Form", key: "formName" },
    { label: "Address", key: "address" },
    { label: "Address 2", key: "address2" },
    { label: "City", key: "city" },
    { label: "State", key: "region" },
    { label: "Zip", key: "zip" },
    { label: "Country", key: "country" },
    { label: "Phone", key: "phone" },
    { label: "Status", key: "state" },
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Failure Code", key: "failureCode" },
    { label: "Failure Message", key: "failureMessage" },
    { label: "Fee Covered", key: "coverFees" },
    { label: "Donation Frequency", key: "donationFrequency" },
    { label: "Fee", key: "fees" },
    { label: "Total Amount Donated", key: "totalAmount" },
    { label: "Gross Donation", key: "grossAmount" },
    { label: "Custom Fields", key: "customInputs" },
    { label: "Crowdfunding Option", key: "crowdfundingOption" },
    { label: "Refunded", key: "refunded" },
    { label: "Imported", key: "importedTransaction" },
    { label: "PayPal Platform Fee", key: "paypalPlatformFee" },
    { label: "Tickets", key: "tickets" },
    { label: "Organization Name", key: "organizationName" },
  ];

  transformRefundedData = (refunded) => {
    if (Array.isArray(refunded)) {
      const transformedData = refunded.map((item) => ({
        // Name: item.name,
        Amount: item.refundAmount,
        Reason: item.reason,
      }));

      // Convert to JSON, replace commas with a different delimiter, and enclose in double quotes
      return '"' + JSON.stringify(transformedData).replace(/,/g, ";") + '"';
    }
    return ""; // Return an empty string if 'refunded' is not an array
  };

  onExportClickHandler = async () => {
    this.setState({ csvLoading: true }, async () => {
      await this.updateData(EXPORT_LIMIT);
    });

    // const { donationList } = this.state;
    // return donationList.map((row) => {
    //   let funds = row.funds ?? [];
    //   //funds = funds.map((fund) => fund.fundName);
    //   return {
    //     ...row,
    //     fund: funds[0]?.fundName,
    //     funds: fundsToCSVString(funds),
    //     tickets: ticketsToCSVString(row.tickets ?? []),
    //     customInputs: customInputsToCSVString(row.customInputs ?? []),
    //     timestamp: formatTimestamp(row.timestamp),
    //     date: formatTimestampToDate(row.timestamp),
    //     refunded: this.transformRefundedData(row.refunded),
    //   };
    // });
  };

  applyAmountFilter = (e) => {
    const { amountAtLeast, amountSpecific, amountNoMoreThan } = this.state;
    // if amountSpecific exists, the other two should equal it or be empty
    if (amountSpecific) {
      if (amountAtLeast !== "" && amountAtLeast !== amountSpecific) {
        this.setState({
          showAmountFilterError: true,
          amountFilterErrorMessage:
            "At Least amount must be equal to the Specific Amount or empty.",
        });
        return;
      }
      if (amountNoMoreThan !== "" && amountNoMoreThan !== amountSpecific) {
        this.setState({
          showAmountFilterError: true,
          amountFilterErrorMessage:
            "No More Than amount must be equal to the Specific Amount or empty.",
        });
        return;
      }
    }
    // if amountAtLeast exists, amountSpecific should be empty or equal to it
    // amountNoMoreThan should be empty or greater or equal to it
    if (amountAtLeast) {
      if (amountSpecific !== "" && amountSpecific !== amountAtLeast) {
        this.setState({
          showAmountFilterError: true,
          amountFilterErrorMessage:
            "Specific Amount must be equal to the At Least amount or empty.",
        });
        return;
      }
      if (amountNoMoreThan !== "" && +amountNoMoreThan < +amountAtLeast) {
        this.setState({
          showAmountFilterError: true,
          amountFilterErrorMessage:
            "No More Than amount must be greater than or equal to the At Least amount.",
        });
        return;
      }
    }
    // if amountNoMoreThan exists, amountSpecific should be empty or equal to it\
    // amountAtLeast should be empty or less or equal to it
    if (amountNoMoreThan) {
      if (amountSpecific !== "" && amountSpecific !== amountNoMoreThan) {
        this.setState({
          showAmountFilterError: true,
          amountFilterErrorMessage:
            "Specific Amount must be equal to the No More Than amount or empty.",
        });
        return;
      }
      if (amountAtLeast !== "" && +amountAtLeast > +amountNoMoreThan) {
        this.setState({
          showAmountFilterError: true,
          amountFilterErrorMessage:
            "At Least amount must be less than or equal to the No More Than amount.",
        });
        return;
      }
    }
    let amountIndicatorValue = "";
    if (amountAtLeast !== "") {
      amountIndicatorValue +=
        amountIndicatorValue == ""
          ? `>$${amountAtLeast}`
          : `, >$${amountAtLeast}`;
    }
    if (amountNoMoreThan !== "") {
      amountIndicatorValue +=
        amountIndicatorValue == ""
          ? `<$${amountNoMoreThan}`
          : `, <$${amountNoMoreThan}`;
    }
    if (amountSpecific !== "") {
      amountIndicatorValue +=
        amountIndicatorValue == ""
          ? `=$${amountSpecific}`
          : `, =$${amountSpecific}`;
    }
    this.setState({
      amountIndicatorValue: amountIndicatorValue,
    });
    this.setShowAmountFilterError(false);
    this.updateData();
  };

  render() {
    const {
      isHovered,
      donationList,
      start,
      end,
      total,
      showFilter,
      timerange,
      showDateFilter,
      showKeywordFilter,
      showAmountFilter,
      showMethodFilter,
      showStatusFilter,
      whoIsLoggedIn,
      filteredFund,
      loading,
      totalDonation,
      avgDonation,
      totalDonors,
      duplicates,
      recentFundsText,
      recentDonorsText,
      recentEmailsText,
      recentFormsText,
      savedFilterIndicatorAmount,
      savedFilterIndicatorDate,
      savedFilterIndicatorKeywordDonor,
      savedFilterIndicatorKeywordFund,
      savedFilterIndicatorKeywordSearch,
      savedFilterIndicatorMethod,
      savedFilterIndicatorStatus,
      showIndicators,
      listOfAllPossibleFunds,
      filteredState,
      filteredFeeCovered,
      resetFilter,
      listOfAllPossibleEmails,
      listOfAllPossibleDonors,
      listOfAllPossibleForms,
      filteredFundList,
      filteredDonor,
      filteredDonorList,
      filteredEmailList,
      filteredFormList,
      filteredKeywordSet,
      keywordSearchTerm,
      filteredOperator,
      filteredAmount,
      filteredAmountDisplayed,
      filteredAmountSet,
      filteredPaymentType,
      amountAtLeast,
      amountSpecific,
      amountNoMoreThan,
      filteredList,
      keywordScroll,
      totalCount,
      totalGrossAmount,
    } = this.state;
    this.totalDonation = 0;
    this.avgDonation = 0;
    this.totalDonors = 0;
    this.duplicates = 0;

    const topSpacing = loading ? "mt-14" : "mt-0"; // Adjust 'mt-0' to remove the space when loading

    //this.showIndicators = false
    let filterResultsAtBottom = 0;
    let filterResultsAtBottomBool = false;
    let keys = new Set();
    const headers1 = this.headers1;

    let noFilteredResults = false;
    let savedFilterDivDate = "";
    let savedFilterDivMethod = "";
    let savedFilterDivAmount = "";
    let savedFilterDivStatus = "";
    let savedFilterDivKeyword = "";
    const stateOptions = [
      {
        option: "Success",
        descriptor: "Donation was successfully transferred.",
      },
      {
        option: "Pending",
        descriptor:
          "A donation has been initiated and a hold may be placed on your account, but it has not completed.",
      },
      {
        option: "Failed",
        descriptor: "No donation was transfered.",
      },
      {
        option: "Refunded",
        descriptor: "Transaction was stopped or sent back to the purchaser.",
        searchValues: ["Partial Refund", "Full Refund"], // New property
      },
      {
        option: "Disputed",
        descriptor: "Transaction was disputed by the purchaser.",
      },
      /*}
      {
        option: "Partial Refund",
        descriptor:
          "Transaction was fully refunded by you, a teammate, or CharityStack employee after the donation was transfered.",
      },
      {
        option: "Full Refund",
        descriptor:
          "Transaction was partially refunded by you, a teammate, or CharityStack employee after the donation was transfered.",
      }, */
    ];

    const paymentTypes = [
      "Card",
      "Apple Pay",
      "Google Pay",
      "Pay By Bank",
      "PayPal",
      "Venmo",
    ];
    let filteredListCopy = donationList;
    let filteredListCopyHolder = donationList;
    //criteria for saved indicators
    if (
      savedFilterIndicatorAmount === true ||
      savedFilterIndicatorDate === true ||
      savedFilterIndicatorKeywordDonor === true ||
      savedFilterIndicatorKeywordFund === true ||
      savedFilterIndicatorKeywordSearch === true ||
      savedFilterIndicatorMethod === true ||
      savedFilterIndicatorStatus === true ||
      //only for filtering on keyword
      (keywordSearchTerm.length !== 0 && filteredDonorList.length !== 0) ||
      (keywordSearchTerm.length !== 0 && filteredFundList.length !== 0) ||
      (keywordSearchTerm.length !== 0 && filteredFormList.length !== 0) ||
      (keywordSearchTerm.length !== 0 && filteredEmailList.length !== 0) ||
      filteredDonorList.length >= 1 ||
      filteredFundList.length >= 1 ||
      filteredFormList.length >= 1 ||
      filteredEmailList.length >= 1 ||
      (keywordSearchTerm.length !== 0 &&
        filteredDonorList.length !== 0 &&
        filteredEmailList.length !== 0 &&
        filteredFundList.length !== 0 &&
        filteredFormList.length !== 0)
    ) {
      this.showIndicators = true;
    }

    if (resetFilter === true) {
      filteredListCopy = filteredListCopyHolder;
    }
    if (filteredListCopy.length === 0) {
      noFilteredResults = true;
    }
    // if (resetFilter === false) {
    //   if (filteredDonorList.length !== 0) {
    //     filteredListCopy = filteredListCopy.filter((t) =>
    //       filteredDonorList.includes(t.name)
    //     );
    //   }
    //   if (filteredFundList.length !== 0) {
    //     filteredListCopy = filteredListCopy.filter((t) => {
    //       let Tfunds = t.funds ?? [];
    //       if (Tfunds.length > 0) {
    //         return Tfunds.some((fund) =>
    //           filteredFundList.includes(fund.fundName)
    //         );
    //       }
    //       return filteredFundList.includes(t.fund);
    //     });
    //   }
    //   if (filteredFormList.length !== 0) {
    //     filteredListCopy = filteredListCopy.filter((t) =>
    //       filteredFormList.includes(t.formName)
    //     );
    //   }
    //   if (filteredListCopy.length === 0) {
    //     noFilteredResults = true;
    //   }
    // }
    if (savedFilterIndicatorDate === true) {
      savedFilterDivDate = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorDateClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            {" "}
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              Date: {timerange}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }
    let tempKeywordFilter = "";
    if (
      savedFilterIndicatorKeywordDonor === true ||
      savedFilterIndicatorKeywordFund === true ||
      savedFilterIndicatorKeywordSearch === true ||
      filteredFundList.length >= 1 ||
      filteredDonorList.length >= 1 ||
      filteredFormList.length >= 1 ||
      filteredEmailList.length >= 1
    ) {
      // if ((keywordSearchTerm.length !== 0 && filteredDonorList.length === 0 && filteredFundList.length === 0)) {
      //   tempKeywordFilter = "Keyword: " + keywordSearchTerm
      // }
      // if (filteredDonorList.length !== 0 && filteredFundList.length === 0 && keywordSearchTerm.length === 0) {
      //   tempKeywordFilter = "Keyword: " + filteredDonorList
      // }
      // if ((filteredFundList.length !== 0 && filteredDonorList.length === 0 && keywordSearchTerm.length === 0)) {
      //   tempKeywordFilter = "Keyword: " + filteredFundList
      // }

      if (
        (keywordSearchTerm.length !== 0 && filteredDonorList.length !== 0) ||
        (keywordSearchTerm.length !== 0 && filteredFundList.length !== 0) ||
        (keywordSearchTerm.length !== 0 && filteredFormList.length !== 0) ||
        (keywordSearchTerm.length !== 0 && filteredEmailList.length !== 0) ||
        filteredFundList.length >= 1 ||
        filteredDonorList.length >= 1 ||
        filteredFormList.length >= 1 ||
        filteredEmailList.length >= 1 ||
        (keywordSearchTerm.length !== 0 &&
          filteredEmailList.length !== 0 &&
          filteredDonorList.length !== 0 &&
          filteredFundList.length !== 0 &&
          filteredFormList.length !== 0)
      ) {
        let keywordIndicator = `Keyword (${
          filteredEmailList.length +
          filteredDonorList.length +
          filteredFundList.length +
          filteredFormList.length
        })`;
        if (
          filteredEmailList.length +
            filteredFundList.length +
            filteredDonorList.length +
            filteredFormList.length ==
          1
        ) {
          // show non empty values in filteredFundList or filteredDonorList
          keywordIndicator =
            "Keyword: " +
            filteredFundList.filter((s) => s != "") +
            filteredDonorList.filter((s) => s != "") +
            filteredFormList.filter((s) => s != "") +
            filteredEmailList.filter((s) => s != "");
        }

        savedFilterDivKeyword = (
          <button
            type="button "
            className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
            onClick={this.onSavedIndicatorKeywordClick}
            onMouse
          >
            <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
              &nbsp;
              <div className="text-gray-500 hover:text-gray-900 ">
                {" "}
                {keywordIndicator}&nbsp;{" "}
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 22 22"
                stroke-width="1"
                stroke="currentColor"
                class="h-5 w-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
          </button>
        );
      }
    }
    if (filteredPaymentType.length > 0) {
      let methodIndicator = `Method (${filteredPaymentType.length})`;
      if (filteredPaymentType.length == 1) {
        // show non empty values in filteredFundList or filteredDonorList
        methodIndicator =
          "Method: " + filteredPaymentType.filter((s) => s != "");
      }
      savedFilterDivMethod = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorMethodClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            {" "}
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              {methodIndicator}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }
    if (savedFilterIndicatorAmount === true) {
      savedFilterDivAmount = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorAmountClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            {" "}
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              Amount: {this.state.amountIndicatorValue}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }
    if (savedFilterIndicatorStatus === true) {
      let statusIndicator = `Status (${filteredState.length})`;
      let refundState = ["Partial Refund", "Full Refund", "Refunded"];

      if (filteredState.length == 1) {
        // show non empty values in filteredFundList or filteredDonorList
        statusIndicator = "Status: " + filteredState.filter((s) => s != "");
      }
      if (
        refundState.length === filteredState.length &&
        refundState.every((val) => filteredState.includes(val))
      ) {
        statusIndicator = "Status: Refunded";
      }
      savedFilterDivStatus = (
        <button
          type="button "
          className="focus:outline-none inline-block flex px-1 focus:ring-2 focus:ring-offset-2"
          onClick={this.onSavedIndicatorStatusClick}
          onMouse
        >
          <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
            {" "}
            &nbsp;
            <div className="text-gray-500 hover:text-gray-900 ">
              {" "}
              {statusIndicator}&nbsp;{" "}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 22 22"
              stroke-width="1"
              stroke="currentColor"
              class="h-5 w-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </button>
      );
    }
    let donationTableExport = filteredListCopy
      .slice(0, total)
      .map((donation, index) => {
        donation.email = "" + donation.email;
        donation.name = "" + donation.name;
        let key =
          donation.email.toString().toLowerCase() +
          "-" +
          donation.name.toString().toLowerCase();
        if (donation.state === "Failed" && !donation.failureCode) {
          console.log("in 837");
          // using https://kinsta.com/blog/list-of-credit-card-declined-codes/
          donation.failureCode = "Other Error";
          donation.failureMessage =
            "The issuing bank can't specify the error, but something went wrong with the transaction. The cardholder needs to contact their card issuer for more information.";
        }
        if (donation.failureCode) {
          donation.failureCode = donation.failureCode.replace(/_/g, " ");
          donation.failureCode = donation.failureCode.toLowerCase();
        }
        if (donation.failureCode === "invalid cvv") {
          donation.failureCode = "Invalid CVV";
        }
        if (
          donation.failureMessage ===
          "The transaction could not be found in Finix."
        ) {
          donation.failureCode = "Unknown CharityStack Failure Code";
          donation.failureMessage =
            "This transaction failed for an unknown reason, and the cardholder needs to contact their card issuer for more information.";
        }
        if (
          donation.state === "Failed" ||
          donation.state === "Pending" ||
          donation.state === "Refunded" ||
          donation.state === "Partial Refund" ||
          donation.state === "Full Refund" ||
          donation.state === "Disputed"
        ) {
          filterResultsAtBottom = filterResultsAtBottom + 1;
          filterResultsAtBottomBool = true;
        }
        if (donation.state === "Success") {
          this.totalDonors = this.totalDonors + 1;
          this.totalDonation =
            this.totalDonation + parseFloat(donation.totalAmount);
        }
        // Refunds logic for scorecards
        // full refund
        if (donation.state === "Full Refund") {
          // this.totalDonors = this.totalDonors + 1;
        }
        // Partial refund
        if (donation.state === "Partial Refund") {
          console.log("Found a partial refund. Total Donors: ", totalDonors);
          console.log(
            "Found a partial refund. Total Donation: ",
            totalDonation
          );

          // If there's a partial refund then technically the donor still exists so total donors should be incremented
          this.totalDonors = this.totalDonors + 1;

          // Calculate total refunded amount
          let totalRefunded = 0;
          if (Array.isArray(donation.refunded)) {
            totalRefunded = donation.refunded.reduce(
              (sum, item) => sum + (parseFloat(item.refundAmount) || 0),
              0
            );
          }
          console.log("Total refunded amount: ", totalRefunded);
          // The total donation should have the refund amount subtracted from it
          this.totalDonation =
            this.totalDonation +
            parseFloat(donation.totalAmount) -
            totalRefunded;
        }

        if (!keys.has(key)) {
          if (donation.state === "Success") {
            keys.add(key);
          }
          // partial refunds logic for scorecards\
          // Partial refund
          // partial refunds indicate some amount was still donated, and therefore, should be counted as a key, where a key is a
          if (
            donation.state === "Partial Refund" ||
            donation.state === "Full Refund"
          ) {
            keys.add(key);
          }
        }
        this.duplicates = keys.size;
        this.avgDonation = (this.totalDonation / this.totalDonors).toFixed(2);
      });
    if (
      this.end > filterResultsAtBottom &&
      filterResultsAtBottomBool === true
    ) {
      this.end = filterResultsAtBottom;
    }
    let donationTableContent = donationList
      .slice(start, end)
      .map((donation, index) => {
        //fix data formatting
        const unformattedTimestamp = donation.timestamp.split(" ")[0];
        const timestamp = this.convertTimestamp(unformattedTimestamp);

        //convert amount and coverFees to correct types
        donation.amount = donation.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
        // donation.fees = donation.fees.toLocaleString(undefined, {
        //   minimumFractionDigits: 2,
        // });
        donation.coverFees = String(donation.coverFees);

        //set color of true/false and the Total amount
        let feesColor = "text-center text-light-blue-500";
        if (donation.coverFees === "No") {
          feesColor = "text-center text-red-400";
        }

        let fund = "";
        let newFund = donation.fund;
        let stateColor = "text-center text-green-800 bg-green-100 rounded-full";
        //set color for SUCCEEDED (green), PENDING (blue), REFUNDED (yellow), FAILED (red), and CANCELED (red)
        if (donation.state === "Pending") {
          stateColor = "text-center text-blue-800 bg-blue-200 rounded-full";
        }
        if (
          donation.state === "Refunded" ||
          donation.state === "Partial Refund" ||
          donation.state === "Full Refund"
        ) {
          stateColor = "text-center text-gray-800 bg-gray-200 rounded-full";
        }
        if (donation.state === "Failed" || donation.state === "Disputed") {
          stateColor = "text-center text-red-800 bg-red-200 rounded-full";
        }

        let fullRefundTitle = "Refund";
        let fullRefundDescription = "A full refund was initiated.";
        if (
          donation.state === "Full Refund" &&
          donation.ACHReversalCode &&
          donation.ACHReversalReason
        ) {
          fullRefundTitle = "ACH Reversal";
          fullRefundDescription = `Code: ${donation.ACHReversalCode}, Reason: ${donation.ACHReversalReason}`;
        }

        // for partial refund, find the amount refunded by aggregating all of the refunds for this transaction
        let partialRefundAmount = 0;
        if (donation.state === "Partial Refund") {
          const refunds =
            this.state.transactionMap[donation.transactionID].refunded;
          for (let i = 0; i < refunds.length; i++) {
            partialRefundAmount += parseFloat(refunds[i].refundAmount);
          }
        }

        if (donation.email == "maxwellstm@gmail.com") {
          console.log("hello there", donation);
        }

        while (donation.state !== null) {
          return (
            <tr
              key={index}
              className="position-relative cursor-pointer hover:bg-gray-50"
              id={donation.transactionID}
              onClick={() => this.openSlideOver(donation.transactionID)}
            >
              <td className="p-2">
                <div className="items-left flex">
                  <div className="text-xs text-gray-800">
                    {formatTimestampToLocal(donation.timestamp)}
                  </div>
                </div>
              </td>
              <td className="p-2">
                <div className="text-left text-xs">{donation.name}</div>
              </td>
              <td className="p-2">
                <div className="text-left text-xs">{donation.email}</div>
              </td>
              {/*               <td className="p-2">
                <div className="text-left text-xs">{newFund}</div>
              </td> */}
              <td className="p-2">
                <div className="text-left text-xs">
                  $
                  {(
                    Math.round(donation.grossAmount * Math.pow(10, 2)) /
                    Math.pow(10, 2)
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </td>
              <td className="p-2">
                <div className="text-left text-xs">
                  $
                  {(
                    Math.round(donation.fees * Math.pow(10, 2)) /
                    Math.pow(10, 2)
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </td>
              <td className="p-2">
                <div className="text-left text-xs">{donation.coverFees}</div>
              </td>
              <td className="p-2">
                <div className="text-left text-xs">
                  $
                  {(
                    Math.round(donation.totalAmount * Math.pow(10, 2)) /
                    Math.pow(10, 2)
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </div>
              </td>
              <td className="relative p-2 text-center">
                <div
                  onMouseEnter={() => this.handleHover(index)}
                  onMouseLeave={() => this.handleHover(null)}
                  className={`${stateColor} inline-block w-28 cursor-pointer hover:text-black`}
                >
                  {donation.state}
                </div>
                {this.state.hoveredIndex === index &&
                  donation.state === "Failed" && (
                    <div
                      onMouseEnter={() => this.handleHover(index)}
                      onMouseLeave={() => this.handleHover(null)}
                      className="absolute inset-0 bottom-0 left-0 right-0 z-10 -ml-96 flex h-auto w-auto items-center justify-center py-5 pt-20 text-gray-500"
                    >
                      <div className="z-10 flex items-center justify-center rounded-md border border-gray-300 bg-white">
                        <div className="flex flex-row  items-center justify-center">
                          <div className="items-center justify-center break-keep text-left text-sm">
                            <p className="p-1 pl-3 pr-1 pt-3 font-bold capitalize">
                              {donation.failureCode}
                            </p>
                            <p className="pb-4 pl-3 pr-1 pt-1 text-xs text-gray-400">
                              {donation.failureMessage}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.hoveredIndex === index &&
                  donation.state === "Partial Refund" && (
                    <div
                      onMouseEnter={() => this.handleHover(index)}
                      onMouseLeave={() => this.handleHover(null)}
                      className="absolute z-10 w-48 text-gray-500"
                      style={{ marginLeft: "-48px" }}
                    >
                      <div className="z-10 flex items-start justify-start rounded-md border border-gray-300 bg-white">
                        <div className="flex flex-row  items-start justify-start">
                          <div className="items-start justify-start break-keep text-left text-sm">
                            <p className="p-1 pl-3 pr-1 pt-2 font-bold capitalize">
                              Partial Refund
                            </p>
                            <p className="pb-2 pl-3 pr-1 pt-1 text-xs text-gray-400">
                              A refund of ${partialRefundAmount.toFixed(2)} was
                              initiated.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {this.state.hoveredIndex === index &&
                  donation.state === "Full Refund" && (
                    <div
                      onMouseEnter={() => this.handleHover(index)}
                      onMouseLeave={() => this.handleHover(null)}
                      className="absolute z-10 w-48 text-gray-500"
                      style={{ marginLeft: "-48px" }}
                    >
                      <div className="z-10 flex items-start justify-start rounded-md border border-gray-300 bg-white">
                        <div className="flex flex-row  items-start justify-start">
                          <div className="items-start justify-start break-keep text-left text-sm">
                            <p className="p-1 pl-3 pr-1 pt-2 font-bold capitalize">
                              {fullRefundTitle}
                            </p>
                            <p className="pb-2 pl-3 pr-1 pt-1 text-xs text-gray-400">
                              {fullRefundDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </td>
            </tr>
          );
        }
      });
    if (
      this.avgDonation === NaN ||
      this.avgDonation === Infinity ||
      this.avgDonation === "NaN"
    ) {
      this.avgDonation = 0;
    }
    this.props.getMetrics(
      this.totalDonation,
      this.totalDonors,
      this.avgDonation,
      this.duplicates
    );
    let next =
      "btn bg-white border-gray-100 hover:border-gray-300 text-light w-20";
    let prev = "btn bg-white border-gray-100 hover:border-gray-300 text-light";
    if (end === totalCount) {
      next =
        "btn bg-white border-gray-100 text-gray-300 cursor-not-allowed w-20";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-100 text-gray-300 cursor-not-allowed";
    }

    return (
      <div className="col-span-full xl:col-span-12">
        <TransactionSlideover
          merchant={this.props.merchant}
          open={this.state.open}
          setOpen={this.setOpen}
          updateData={this.updateData}
          setSelectedTransaction={this.setSelectedTransaction}
          selectedTransaction={this.state.selectedTransaction}
          jwtToken={this.state.jwtToken}
          limit={this.state.limit}
        />
        {/* {!loading && !this.showIndicators && (
          <div
            className={`flex flex-row justify-end py-3 ${
              !loading ? "table-visible" : "table-hidden"
            }`}
          >
            <div className="flex">
              <button
                type="button "
                className="focus:outline-none  inline-block flex focus:ring-2 focus:ring-offset-2 "
                onClick={this.onFilterClickHandler}
                onMouse
              >
                <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 17"
                    stroke="#a1afc2"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                    />
                  </svg>
                  &nbsp;
                  <div className="text-gray-500 hover:text-gray-900 ">
                    {" "}
                    Filter &nbsp;&nbsp;&nbsp;{" "}
                  </div>
                </div>
              </button>
            </div>
            <div> &nbsp;</div>
            <div className="flex ">
              <button
                type="button "
                className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2"
              >
                <CSVLink
                  filename="CharityStack Donations Export.csv"
                  data={donationList.map((row) => {
                    let funds = row.funds ?? [];
                    //funds = funds.map((fund) => fund.fundName);
                    return {
                      ...row,
                      fund: funds[0]?.fundName,
                      funds: fundsToCSVString(funds),
                      tickets: ticketsToCSVString(row.tickets ?? []),
                      customInputs: customInputsToCSVString(
                        row.customInputs ?? []
                      ),
                      timestamp: formatTimestamp(row.timestamp),
                      date: formatTimestampToDate(row.timestamp),
                      refunded: transformRefundedData(row.refunded),
                    };
                  })}
                  headers={headers1}
                >
                  <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 17"
                      stroke="#a1afc2"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    &nbsp;
                    <div className="text-gray-500 hover:text-gray-900 ">
                      {" "}
                      Export &nbsp;{" "}
                    </div>
                  </div>
                </CSVLink>
              </button>
            </div>
          </div>
        )} */}
        {!loading && (
          //saved filter indicators
          <div className={`${!loading ? "table-visible" : "table-hidden"}`}>
            <div>
              <div className="flex flex-row justify-between py-3 ">
                <div className="z-10 flex flex-row">
                  {savedFilterDivDate}
                  {savedFilterDivKeyword}
                  {savedFilterDivAmount}
                  {savedFilterDivMethod}
                  {savedFilterDivStatus}
                </div>
                <div className="flex flex-row justify-end">
                  <div className="inline">
                    <button
                      type="button "
                      className="focus:outline-none  inline-block flex focus:ring-2 focus:ring-offset-2 "
                      onClick={this.onFilterClickHandler}
                      onMouse
                    >
                      <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 17"
                          stroke="#a1afc2"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                          />
                        </svg>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Filter &nbsp;&nbsp;&nbsp;{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div> &nbsp;</div>
                  <div className="inline ">
                    <button
                      type="button "
                      className="focus:outline-none inline-block flex focus:ring-2 focus:ring-offset-2"
                      onClick={this.onExportClickHandler}
                    >
                      <div className="flex justify-center rounded-lg border border-[#D1D9E0] bg-white p-1 text-sm shadow-sm ">
                        <div
                          className={
                            this.state.csvLoading ? "animate-bounce" : ""
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 17"
                            stroke="#a1afc2"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                          </svg>
                        </div>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Export &nbsp;{" "}
                        </div>
                      </div>
                    </button>

                    <CSVLink
                      ref={this.csvLinkRef}
                      filename="CharityStack Donations Export.csv"
                      data={this.state.exportList.map((row) => {
                        let funds = row.funds ?? [];
                        //funds = funds.map((fund) => fund.fundName);
                        return {
                          ...row,
                          fund: funds[0]?.fundName,
                          funds: fundsToCSVString(funds),
                          tickets: ticketsToCSVString(row.tickets ?? []),
                          customInputs: row.customInputs
                            ? row.customInputs
                                .map((input, index) => {
                                  let customInput = ""; // Initialize customInput

                                  const requiredMessage = input.required
                                    ? ""
                                    : " (not required)";

                                  if (input.answers[0] !== undefined) {
                                    customInput +=
                                      input.title +
                                      ": " +
                                      input.answers[0] +
                                      requiredMessage +
                                      ";";
                                  } else {
                                    customInput +=
                                      input.title +
                                      ": No Input" +
                                      requiredMessage +
                                      ";";
                                  }

                                  return customInput; // Return customInput after constructing it
                                })
                                .join("")
                            : [],
                          timestamp: formatTimestamp(row.timestamp),
                          date: formatTimestampToDate(row.timestamp),
                          refunded: this.transformRefundedData(row.refunded),
                        };
                      })}
                      headers={headers1}
                    ></CSVLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showFilter && (
          <div class="container relative mx-auto bg-gray-500">
            <div
              // style={{ height: "402px", maxHeight: "402px" }}
              class="
              border-sky-500 absolute right-28 z-10 w-[600px] items-stretch justify-around gap-4 divide-x rounded-lg border-2 border-solid bg-white p-4 text-sm drop-shadow-xl"
            >
              <div class="flow-root divide-x">
                <div class="py-15 float-left grid grid-rows-6 gap-4 px-1 pt-7 ">
                  <div class="block">
                    <button
                      type="button "
                      className="focus:outline-none"
                      onClick={this.onFilterDateFilter}
                    >
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                          />
                        </svg>{" "}
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Date &nbsp;&nbsp;&nbsp;{" "}
                        </div>
                      </div>
                    </button>
                  </div>

                  <div>
                    <button
                      type="button "
                      className="focus:outline-none"
                      onClick={this.onFilterKeywordFilter}
                    >
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                            clipRule="evenodd"
                          />
                        </svg>{" "}
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Keyword{" "}
                        </div>
                      </div>
                    </button>
                  </div>

                  <div>
                    <button
                      type="button "
                      className="focus:outline-none"
                      onClick={this.onFilterAmountFilter}
                    >
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>{" "}
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Amount{" "}
                        </div>
                      </div>
                    </button>
                  </div>

                  <div>
                    <button
                      type="button "
                      className="focus:outline-none"
                      onClick={this.onFilterMethodFilter}
                    >
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                          />
                        </svg>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900 ">
                          {" "}
                          Method{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="focus:outline-none"
                      onClick={this.onFilterStatusFilter}
                    >
                      <div className="inline-block flex justify-center align-baseline">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 20"
                          stroke-width="1.5"
                          stroke="#a1afc2"
                          class="h-5 w-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 6h.008v.008H6V6z"
                          />
                        </svg>
                        &nbsp;
                        <div className="text-gray-500 hover:text-gray-900">
                          {" "}
                          Status{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="py-2"></div>
                  <div className="py-2"></div>
                  <div className="py-2"></div>
                  <div>
                    <button type="button " onClick={this.onResetFilter}>
                      <div className="inline-block flex justify-center align-baseline">
                        <div className="text-red-700 hover:text-red-300 ">
                          {" "}
                          Clear Filters{" "}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="flex content-start justify-start border-none">
                  <div>
                    {showDateFilter && (
                      <div className="px-5">
                        <DonationDatePicker
                          setTimerange={this.setDateTimeRange}
                        />
                      </div>
                    )}
                  </div>

                  {showKeywordFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-full rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div class="relative mt-1 flex rounded-md">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span class="text-gray-500 sm:text-sm">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 22 22"
                              stroke-width="1.5"
                              stroke="#a1afc2"
                              class="h-4 w-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </div>
                        {/* <label htmlFor="keywordsearch" className="sr-only">
                          Search
                        </label> */}
                        <div className="flex w-full overflow-x-hidden rounded-md border border-gray-300">
                          <input
                            type="search"
                            name="keywordsearch"
                            id="keywordsearch"
                            className="w-3/4 rounded-md border-none pl-8 pt-3 text-sm shadow-sm focus:border-none focus:ring-0"
                            placeholder="Search for keywords"
                            value={keywordSearchTerm}
                            onChange={(e) => this.onKeywordSearchFilter(e)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                this.onKeywordSearchApply(e);
                              }
                            }}
                          />
                          <button
                            type="button"
                            className="focus:outline-none w-1/4 items-center border-l border-gray-300 px-3"
                            onClick={(e) => this.onKeywordSearchApply(e)}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                      <div
                        onMouseEnter={() => this.handleKeywordScroll(true)}
                        onMouseLeave={() => this.handleKeywordScroll(false)}
                        className={`overflow-y-auto overflow-x-hidden`}
                        style={{
                          width: "100%",
                          height: "320px",
                          maxHeight: "320px",
                        }}
                      >
                        {listOfAllPossibleDonors.length != 0 && (
                          <>
                            <div className="pt-4 text-base underline">
                              {recentDonorsText}
                            </div>

                            {listOfAllPossibleDonors.map((donor) => (
                              <div
                                key={donor}
                                className="flex items-start justify-start"
                              >
                                <input
                                  type="checkbox"
                                  className="z-20 h-4 w-4 flex-shrink-0 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                                  onChange={(e) =>
                                    this.onFilteredFundDonorClickHandler(e)
                                  }
                                  value={donor.toString()}
                                  checked={filteredDonorList.includes(donor)}
                                />
                                <span className="ml-2">{donor.toString()}</span>
                              </div>
                            ))}
                          </>
                        )}
                        {listOfAllPossibleEmails.length != 0 && (
                          <>
                            <div className="pt-4 text-base underline">
                              {recentEmailsText}
                            </div>

                            {listOfAllPossibleEmails.map((email) => (
                              <div
                                key={email}
                                className="flex items-start justify-start"
                              >
                                <input
                                  type="checkbox"
                                  className="z-20 h-4 w-4 flex-shrink-0 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                                  onChange={(e) =>
                                    this.onFilteredEmailClickHandler(e)
                                  }
                                  value={email.toString()}
                                  checked={filteredEmailList.includes(email)}
                                />
                                <span className="ml-2">{email.toString()}</span>
                              </div>
                            ))}
                          </>
                        )}
                        {listOfAllPossibleForms.length != 0 && (
                          <>
                            <div className="pt-4 text-base underline">
                              {recentFormsText}
                            </div>
                            {listOfAllPossibleForms.map((formName) => {
                              return (
                                <div className="justify-begin justify-left flex items-center justify-start">
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                                    onChange={(e) =>
                                      this.onFilteredFormClickHandler(e)
                                    }
                                    value={formName.toString()}
                                    checked={filteredFormList.includes(
                                      formName
                                    )}
                                  />{" "}
                                  &nbsp;
                                  {formName.toString()}
                                </div>
                              );
                            })}
                          </>
                        )}
                        {listOfAllPossibleFunds.length != 0 && (
                          <>
                            <div className="pt-4 text-base underline">
                              {recentFundsText}
                            </div>
                            {listOfAllPossibleFunds.map((newFund) => {
                              return (
                                <div className="justify-begin justify-left flex items-center justify-start">
                                  <input
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-0"
                                    onChange={(e) =>
                                      this.onFilteredFundClickHandler(e)
                                    }
                                    value={newFund.toString()}
                                    checked={filteredFundList.includes(newFund)}
                                  />{" "}
                                  &nbsp;
                                  {newFund.toString()}
                                </div>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {showAmountFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-72 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>

                      <div className="py-3">
                        Specific Amount
                        <input
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.applyAmountFilter();
                            }
                          }}
                          maxLength={6}
                          type="search"
                          name="amountSpecific"
                          id="amountSpecific"
                          className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="=$0.00"
                          onChange={(e) => this.amountFilterChange(e)}
                          value={amountSpecific}
                        />
                      </div>
                      <div className="py-3">
                        At Least...
                        <input
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.applyAmountFilter();
                            }
                          }}
                          maxLength={6}
                          type="search"
                          name="amountAtLeast"
                          id="amountAtLeast"
                          className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder=">=$0.00"
                          onChange={(e) => this.amountFilterChange(e)}
                          value={amountAtLeast}
                        />
                      </div>
                      <div className="py-3">
                        No More Than...
                        <input
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              this.applyAmountFilter();
                            }
                          }}
                          maxLength={6}
                          type="search"
                          name="amountNoMoreThan"
                          id="amountNoMoreThan"
                          className="block w-full rounded-md border-gray-300 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="<=$0.00"
                          onChange={(e) => this.amountFilterChange(e)}
                          value={amountNoMoreThan}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn text-light ml-auto block w-20 items-center border-gray-100 bg-white py-3 hover:border-gray-300"
                        onClick={() => this.applyAmountFilter()}
                      >
                        Apply
                      </button>
                      {this.state.showAmountFilterError && (
                        <div className="w-72 py-3">
                          <ErrorAlert
                            message={this.state.amountFilterErrorMessage}
                            setShowErrorAlert={this.setShowAmountFilterError}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {showMethodFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-72 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div className="pt-6 text-base underline">Method</div>
                      {paymentTypes.map((donor) => {
                        return (
                          <div className="justify-begin justify-left flex items-center justify-start py-1">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                              onChange={(e) =>
                                this.onFilteredPaymentTypeClickHandler(e)
                              }
                              value={donor.toString()}
                              checked={filteredPaymentType.includes(donor)}
                            />{" "}
                            &nbsp;
                            {donor.toString()}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {showStatusFilter && (
                    <div className="justify-start pl-7 pr-6">
                      <div className="w-72 rounded-md border-gray-300 pl-8 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500"></div>
                      <div className="pt-6 text-base underline">Status</div>
                      {stateOptions.map((donor, index) => {
                        return (
                          <div className="justify-begin justify-left flex items-center justify-start">
                            <label>
                              <input
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 py-1 text-indigo-600 focus:ring-0"
                                onChange={(e) =>
                                  this.onFilteredStateClickHandler(e)
                                }
                                value={donor.option.toString()}
                                //     checked={filteredState.includes(donor.option)}
                                checked={filteredState.includes(donor.option)}
                              />{" "}
                              &nbsp;
                              {donor.option.toString()}
                              <div className="text-xxs text-grey-500 w-72 pb-3 pl-6  text-gray-500">
                                {stateOptions[index].descriptor.toString()}
                              </div>
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`col-span-full rounded-lg border border-gray-200 bg-white shadow-sm xl:col-span-12 ${topSpacing}`}
        >
          {/* Table */}
          {loading && (
            <div className="flex">
              <ContentLoader
                speed={2}
                width="100%"
                height={totalHeight}
                preserveAspectRatio="xMidYMid meet"
                backgroundColor="#f0f0f0"
                foregroundColor="#dedede"
              >
                {Array.from({ length: numberOfRows }).map((_, rowIndex) => {
                  return (
                    <React.Fragment key={`row-${rowIndex}`}>
                      {adjustedColumns.map((col, colIndex) => {
                        const isHeader = rowIndex === 0;
                        const bgColor = isHeader ? "#b1b1b1" : "#f0f0f0";
                        const currentX = calculateXPosition(
                          colIndex,
                          singleSpacingPercent
                        );

                        return (
                          <rect
                            key={`row-${rowIndex}-col-${colIndex}`}
                            x={`${currentX}%`} // Use the function to calculate x position
                            y={rowIndex * (rowHeight + spaceBetweenRows) + 20}
                            rx="5"
                            ry="5"
                            width={col.width} // Use the adjusted width percentage
                            height={rowHeight}
                            fill={bgColor}
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </ContentLoader>
            </div>
          )}

          <div className="p-3">
            <div>
              {noFilteredResults && !loading && (
                <div
                  class="rounded-2xl border border-blue-100 bg-white p-8 shadow-lg "
                  role="alert"
                  id="noMatchFound"
                >
                  <div class="items-center sm:flex">
                    <span class="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#a1afc2"
                        class="h-6 w-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                        />
                      </svg>
                    </span>

                    <p class="mt-3 text-lg font-medium sm:ml-3 sm:mt-0">
                      No matches found
                    </p>
                  </div>

                  <p class="mt-4 text-gray-500">
                    Unfortunately, no results match the search criteria you
                    entered. Try making a change to the filters you selected.
                    <br></br> If this issue persists, please reach out to the{" "}
                    <a href="mailto:support@charitystack.org">
                      <span className="text-blue-400 hover:animate-spin">
                        {" "}
                        CharityStack team!
                      </span>{" "}
                    </a>
                  </p>
                  {/*}
             <div class="mt-6 sm:flex">
               <a
                 class="inline-block w-full px-5 py-3 text-sm font-semibold text-center text-white bg-blue-500 rounded-lg sm:w-auto"
                 href=""
               >
                 Take a Look
               </a>
           
               <a
                 class="inline-block w-full px-5 py-3 mt-3 text-sm font-semibold text-center text-gray-500 rounded-lg bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto"
                 href=""
               >
                 Mark as Read
               </a>
             </div> */}
                </div>
              )}
              <div className="overflow-x-auto">
                {!noFilteredResults && !loading && (
                  <div className="overflow-x-auto">
                    <table className="w-full table-auto">
                      {/* Table header */}
                      <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                        <tr>
                          <th className="p-2">
                            <div className="text-left font-semibold">Date</div>
                          </th>
                          <th className="p-2">
                            <div className="text-left font-semibold">Name</div>
                          </th>
                          <th className="p-2">
                            <div className="text-left font-semibold">Email</div>
                          </th>
                          {/*  
                         <th className="p-2">
                            <div className="font-semibold text-left">Funds</div>
                          </th>
                     */}
                          <th className="p-2">
                            <div className="text-left font-semibold">
                              Gross Amount
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-left font-semibold">Fee</div>
                          </th>
                          <th className="p-2">
                            <div className="text-left font-semibold">
                              Fee Covered
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-left font-semibold">
                              Total Amount
                            </div>
                          </th>
                          <th className="p-2">
                            <div className="text-center font-semibold">
                              Status
                            </div>
                          </th>
                        </tr>
                      </thead>
                      {/* Table body */}
                      <tbody className="divide-y divide-gray-100 text-sm font-medium">
                        {donationTableContent}
                        {donationTableExport}
                        <br></br>
                      </tbody>
                    </table>
                    <div className="mt-8">
                      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                        <nav
                          className="mb-4 sm:order-1"
                          role="navigation"
                          aria-label="Navigation"
                        >
                          <ul className="flex justify-center">
                            <li className="ml-3 first:ml-0">
                              <a
                                className={prev}
                                href="#0"
                                onClick={this.goPrev}
                              >
                                &lt;- Previous
                              </a>
                            </li>
                            <li className="ml-3 first:ml-0">
                              {this.state.nextLoading ? (
                                <button
                                  type="button"
                                  className="btn w-20 cursor-not-allowed border-gray-100 bg-white text-gray-300"
                                  disabled={true}
                                >
                                  <div className="flex items-center justify-center space-x-2">
                                    <svg
                                      className="h-5 w-5 animate-spin text-gray-300"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="text"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  </div>
                                </button>
                              ) : (
                                <a
                                  className={next}
                                  href="#0"
                                  onClick={this.goNext}
                                >
                                  Next -&gt;
                                </a>
                              )}
                            </li>
                          </ul>
                        </nav>
                        {filterResultsAtBottomBool && (
                          <div className="text-center text-sm text-gray-500 sm:text-left">
                            Showing{" "}
                            <span className="font-medium text-gray-600">
                              {start.toLocaleString()}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium text-gray-600">
                              {end.toLocaleString()}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium text-gray-600">
                              {totalCount.toLocaleString()}
                            </span>{" "}
                            results{" | "}
                            <span className="font-medium text-gray-600">
                              $
                              {parseFloat(totalGrossAmount).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </span>
                          </div>
                        )}
                        {!filterResultsAtBottomBool && (
                          <div className="text-center text-sm text-gray-500 sm:text-left">
                            Showing{" "}
                            <span className="font-medium text-gray-600">
                              {start.toLocaleString()}
                            </span>{" "}
                            to{" "}
                            <span className="font-medium text-gray-600">
                              {end.toLocaleString()}
                            </span>{" "}
                            of{" "}
                            <span className="font-medium text-gray-600">
                              {totalCount.toLocaleString()}
                            </span>{" "}
                            results{" | "}
                            <span className="font-medium text-gray-600">
                              $
                              {parseFloat(totalGrossAmount).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DonationsTable;
