import React, { useState, useEffect } from "react";
import decompressData from "../utils/decompressData";
import pako from "pako";
import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import FilterButton from "../partials/actions/FilterButton";
import DonationDatePicker from "../partials/actions/DonationDatePicker";
import DashboardCard01 from "../partials/dashboard/DashboardCard01";
import DashboardCard02 from "../partials/dashboard/DashboardCard02";
import DashboardCard03 from "../partials/dashboard/DashboardCard03";
import DashboardCard04 from "../partials/dashboard/DashboardCard04";
import DashboardCard05 from "../partials/dashboard/DashboardCard05";
import DashboardCard06 from "../partials/dashboard/DashboardCard06";
import DashboardCard07 from "../partials/dashboard/DashboardCard07";
import DashboardCard09 from "../partials/dashboard/DashboardCard09";
import DashboardCard10 from "../partials/dashboard/DashboardCard10";
import DashboardCard11 from "../partials/dashboard/DashboardCard11";
import DonationsTable from "../partials/dashboard/DonationsTableHome";
import PayoutsTable from "../partials/dashboard/PayoutsTableHome";
import DonorsTable from "../partials/dashboard/DonorsTable";
import DonationsChart from "../partials/dashboard/DonationsChartHome";
import Linechart from "../charts/LineChart03";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { queryTransactionsTopFunds } from "../utils/RocksetQueries/TransactionQueries";

function Home(props) {
  //constants
  const table1 = [PayoutsTable];
  const tables = [DonationsTable, DonorsTable, PayoutsTable];
  const { readOnly } = useSelector((state) => state.readOnly); // this contains the variable which youll use to check

  //Define default timerange
  const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
  let year = new Date();
  let yearValue = year.getFullYear();
  let startDate = yearValue + "-01-01";
  const startingDate = new Date(startDate);
  let today = new Date();
  // increment today forward by 1 day
  today.setDate(today.getDate() + 1);
  console.log("today is ");
  console.log({ today });
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() - 6);
  const startTimerange =
    startingDate.toLocaleDateString("en-US", DATE_OPTIONS) +
    " - " +
    today.toLocaleDateString("en-US", DATE_OPTIONS);

  //react hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [timerange, setTimerange] = useState(startTimerange);
  const [tableIndex, setTableIndex] = useState(0);

  const grabBaseMerchant = async (jwtToken) => {
    console.log("grabBaseMerchant");

    const url = process.env.REACT_APP_MERCHANT_URL;
    const requestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
    };

    let baseMerchantResponse = await fetch(url, requestOptions);
    let baseMerchantData = await baseMerchantResponse.json();
    console.log(baseMerchantData);

    props.setMerchant(baseMerchantData);
  };

  const grabSelectableFunds = async (jwtToken) => {
    //call transactions table and get funds when jwtToken is set
    const DATE_OPTIONS = { year: "numeric", month: "short", day: "numeric" };
    let today = new Date();
    // increment today forward by 1 day
    today.setDate(today.getDate() + 1);
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() - 6000);
    const allTimeTimerange =
      tomorrow.toLocaleDateString("en-US", DATE_OPTIONS) +
      " - " +
      today.toLocaleDateString("en-US", DATE_OPTIONS);

    // grab forms data
    const formsRequestOptions = {
      method: "GET",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: jwtToken,
      },
    };

    const formsURL = process.env.REACT_APP_FORMS_URL;

    // make api calls simultaneously
    let formsAPICall = fetch(formsURL, formsRequestOptions);

    let formsResponse = await formsAPICall;
    let formsData = await formsResponse.json();

    let runningFundsList = [];

    // get merchants
    const data = await Auth.currentSession();
    // grab groups from cognito
    const groups = data["idToken"]["payload"]["cognito:groups"];
    // replace the - with a space for every value in the array
    const groupsWithSpaces = groups.map((group) => group.replace(/-/g, " "));

    let donationFundsData = await queryTransactionsTopFunds(
      jwtToken,
      groupsWithSpaces,
      allTimeTimerange,
      -1,
      -1,
      -1,
      [],
      [],
      "",
      999999999
    );

    // grab all historical funds from donations table
    donationFundsData.map((fund) => {
      if (!runningFundsList.includes(fund)) {
        runningFundsList.push(fund);
      }
    });

    // grab all funds from forms
    formsData.map((form) => {
      // events got no funds, check for that
      if (form.funds != undefined) {
        form.funds.map((fund) => {
          if (!runningFundsList.includes(fund)) {
            runningFundsList.push(fund);
          }
        });
      }
    });

    // format funds for react-select
    runningFundsList = runningFundsList.map((fund) => {
      return {
        value: fund,
        label: fund,
        color: "#69995E",
      };
    });
    props.setSelectableForms(formsData);
    props.setSelectableFunds(runningFundsList);
  };

  useEffect(() => {
    const myFunction = async () => {
      if (props.selectableFunds.length === 0) {
        if (!props.jwtToken) {
          const data = await Auth.currentSession();
          props.setJWTToken(data["idToken"]["jwtToken"]);
          grabSelectableFunds(data["idToken"]["jwtToken"]);
          grabBaseMerchant(data["idToken"]["jwtToken"]);
        } else {
          grabSelectableFunds(props.jwtToken);
        }
      }
    };
    myFunction();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        groups={props.groups}
      />

      {/* Content area */}
      <div className="relative flex flex-auto grow flex-col overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <div className="max-w-9xl mx-auto w-full px-4 sm:px-6 lg:px-8">
          <Header
            grabSelectableFunds={grabSelectableFunds}
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            selectableFunds={props.selectableFunds}
            selectableForms={props.selectableForms}
            merchant={props.merchant}
            groups={props.groups}
          />
        </div>

        <main>
          <div className="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            <WelcomeBanner merchant={props.merchant} />

            {/* Right: Actions */}
            <div className="grid grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
              {/* Filter button */}
              {/* <FilterButton /> */}
              {/* DonationDatePicker built with flatpickr */}
              {/*    <DonationDatePicker setTimerange={setTimerange} /> */}
              {/* Add view button */}
            </div>
            <div className="py-1"></div>

            <div className=" mb-4 grid grow grid-cols-1 gap-6">
              <DonationsChart timerange={timerange} />
            </div>

            {/* Cards */}
            {/*
            If the readOnly variable is truthy (evaluates to true), it will return null. In other words, nothing will be rendered in this case.
            If the readOnly variable is falsy (evaluates to false), it will execute the code block enclosed in the parentheses ( and ). This code block can contain JSX elements or other JavaScript code that will be rendered or executed when readOnly is falsy.
            */}
            {/* {readOnly ? (
              <div className="grid grid-cols-1 gap-6">
                <div>
                  {React.createElement(tables[tableIndex], {
                    timerange: timerange,
                  })}
                </div>

                <div></div>
              </div>
            ) : ( */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                {React.createElement(tables[tableIndex], {
                  timerange: timerange,
                })}
              </div>
              <div>
                {React.createElement(table1[tableIndex], {
                  timerange: timerange,
                })}
              </div>
              <div></div>
            </div>
            {/* )} */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Home;
