import { formatDateRockset } from "../formatTimestamp";

const apikey = process.env.REACT_APP_ROCKSET_API_KEY;

const reactENV = process.env.REACT_APP_ENV;
let env = "dev";
if (reactENV == "production") {
  env = "prod";
}

export async function queryTransactionsChart(jwtToken, merchants) {
  const response = await fetch(
    `${process.env.REACT_APP_OPENSEARCH_URL}/transactions`,
    {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "chart",
        filters: {
          query: "transactions",
          startDate: "2020-01-01",
          merchantNames: merchants,
        },
      }),
    }
  );

  const queryResponse = await response.json();
  console.log("queryResponse Transactions Chart", queryResponse);
  return queryResponse;
}

export async function queryTransactions(
  jwtToken,
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  filteredDonorList,
  filteredEmailList,
  filteredFormList,
  filteredFundList,
  limit,
  searchAfter = undefined
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(
    `${process.env.REACT_APP_OPENSEARCH_URL}/transactions`,
    {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "transactions",
        filters: {
          amountEquals: amountSpecific === "" ? "-1" : amountSpecific,
          amountGreaterThan: amountAtLeast === "" ? "-1" : amountAtLeast,
          amountLessThan: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
          endDate: endDate,
          paymentMethods: paymentMethods,
          startDate: startDate,
          states: states,
          merchantNames: merchants,
          formNames: filteredFormList,
          fundNames: filteredFundList,
          names: filteredDonorList,
          emails: filteredEmailList,
          limit: limit,
          ...(searchAfter !== undefined && { searchAfter }), // Conditionally add searchAfter
        },
      }),
    }
  );

  const queryResponse = await response.json();
  console.log("queryResponse", queryResponse);
  return queryResponse;
}

export async function queryTransactionsTopForms(
  jwtToken,
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  console.log("paymentMethods", paymentMethods);
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(
    `${process.env.REACT_APP_OPENSEARCH_URL}/transactions`,
    {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "topForms",
        filters: {
          amountEquals: amountSpecific === "" ? "-1" : amountSpecific,
          amountGreaterThan: amountAtLeast === "" ? "-1" : amountAtLeast,
          amountLessThan: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
          endDate: endDate,
          paymentMethods: paymentMethods,
          startDate: startDate,
          states: states,
          merchantNames: merchants,
          keyword: keywordSearchTerm,
        },
      }),
    }
  );

  const queryResponse = await response.json();
  console.log("queryResponse forms", queryResponse);
  return queryResponse;
}

export async function queryTransactionsTopFunds(
  jwtToken,
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(
    `${process.env.REACT_APP_OPENSEARCH_URL}/transactions`,
    {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "topFunds",
        filters: {
          amountEquals: amountSpecific === "" ? "-1" : amountSpecific,
          amountGreaterThan: amountAtLeast === "" ? "-1" : amountAtLeast,
          amountLessThan: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
          endDate: endDate,
          paymentMethods: paymentMethods,
          startDate: startDate,
          states: states,
          merchantNames: merchants,
          keyword: keywordSearchTerm,
        },
      }),
    }
  );

  const queryResponse = await response.json();
  console.log("queryResponse forms", queryResponse);
  return queryResponse;
}

export async function queryTransactionsTopNames(
  jwtToken,
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(
    `${process.env.REACT_APP_OPENSEARCH_URL}/transactions`,
    {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "topNames",
        filters: {
          amountEquals: amountSpecific === "" ? "-1" : amountSpecific,
          amountGreaterThan: amountAtLeast === "" ? "-1" : amountAtLeast,
          amountLessThan: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
          endDate: endDate,
          paymentMethods: paymentMethods,
          startDate: startDate,
          states: states,
          merchantNames: merchants,
          keyword: keywordSearchTerm,
        },
      }),
    }
  );

  const queryResponse = await response.json();
  console.log("queryResponse forms", queryResponse);
  return queryResponse;
}

export async function queryTransactionsTopEmails(
  jwtToken,
  merchants,
  timerange,
  amountAtLeast,
  amountNoMoreThan,
  amountSpecific,
  filteredPaymentType,
  filteredState,
  keywordSearchTerm
) {
  const paymentMethods = filteredPaymentType
    ? filteredPaymentType.map((paymentType) =>
        paymentType.toUpperCase().replace(/ /g, "_")
      )
    : "";
  const states = filteredState.flatMap((state) => {
    if (state === "Refunded") {
      // add FULL_REFUND, FUND_REFUND, TICKET_REFUND, FEE_REFUND
      return ["FULL_REFUND", "PARTIAL_REFUND"];
    }
    if (state === "Failed") {
      return ["FAILED"];
    }
    if (state === "Pending") {
      return ["PENDING"];
    }
    if (state === "Success") {
      return ["SUCCEEDED"];
    }
    if (state === "Disputed") {
      return ["DISPUTED_OPEN", "DISPUTED_CLOSED"];
    }
  });
  const { startDate, endDate } = timerange
    ? formatDateRockset(timerange)
    : { startDate: "", endDate: "" };
  const response = await fetch(
    `${process.env.REACT_APP_OPENSEARCH_URL}/transactions`,
    {
      method: "POST",
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: "topEmails",
        filters: {
          amountEquals: amountSpecific === "" ? "-1" : amountSpecific,
          amountGreaterThan: amountAtLeast === "" ? "-1" : amountAtLeast,
          amountLessThan: amountNoMoreThan === "" ? "-1" : amountNoMoreThan,
          endDate: endDate,
          paymentMethods: paymentMethods,
          startDate: startDate,
          states: states,
          merchantNames: merchants,
          keyword: keywordSearchTerm,
        },
      }),
    }
  );

  const queryResponse = await response.json();
  console.log("queryResponse forms", queryResponse);
  return queryResponse;
}
