import dayjs from "dayjs";

export function formatTimestamp(timestamp) {
  // convert timestamps to an excel-friendly format
  // need the if statement because safari cant use Date class to parse DD-MM-YYYY formats
  if (timestamp.includes(":")) {
    const date = new Date(timestamp);
    return date.toISOString();
  }
  const date = dayjs(timestamp, "MM-DD-YYYY");
  return date.toISOString();
}

export function formatTimestampToDate(timestamp) {
  // convert timestamps to an excel-friendly format of just the date
  // need the if statement because safari cant use Date class to parse DD-MM-YYYY formats
  if (timestamp.includes(":")) {
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0];
  }
  const date = dayjs(timestamp, "MM-DD-YYYY");
  return date.toISOString().split("T")[0];
}

export function dynamoDBTimestampToDate(timestamp) {
  // Assuming the timestamp is in UTC and needs to be converted to local timezone
  const date = new Date(timestamp + "Z"); // Append 'Z' to indicate UTC
  return (
    `${date.getMonth() + 1}`.padStart(2, "0") +
    "-" +
    `${date.getDate()}`.padStart(2, "0") +
    "-" +
    date.getFullYear()
  );
}

// format 2024-09-05T23:13:51.189117Z to local time, and then format it as MM-DD-YYYY
export function formatTimestampToLocal(timestamp) {
  const date = new Date(timestamp);
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`; // Changed format to MM-DD-YYYY
}

export function formatDateRockset(date) {
  // Check if the date includes a range (indicated by '-')
  if (date.includes("-")) {
    const dates = date.split(" - ");
    const startDate = new Date(dates[0]);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(dates[1]);
    endDate.setHours(23, 59, 59, 999);

    return {
      startDate: startDate.toISOString().replace("Z", "000Z"),
      endDate: endDate.toISOString().replace("Z", "999Z"),
    };
  } else {
    const singleDate = new Date(date);
    singleDate.setHours(23, 59, 59, 999);
    const endDate = singleDate.toISOString().replace("Z", "999Z");
    singleDate.setHours(0, 0, 0, 0);
    const startDate = singleDate.toISOString().replace("Z", "000Z");
    return {
      startDate: startDate,
      endDate: endDate,
    };
  }
}
