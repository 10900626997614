import React, { Component, useState, useEffect, useMemo } from "react";
import decompressData from "../../utils/decompressData";
import pako from "pako";

import { StrictMode } from "react/cjs/react.production.min";
import PaginationClassic from "../PaginationClassic";
import moment from "moment";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import axios from "axios";
import { queryTransactions } from "../../utils/RocksetQueries/TransactionQueries";
import { formatTimestampToLocal } from "../../utils/formatTimestamp";
export class DonationsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jwtToken: "",
      donationList: [],
      start: 0,
      end: 0,
      total: 0,
      grossAmountList: [],
      totalAmountList: [],
    };
  }

  componentDidUpdate(prevProps) {
    // console.log("inside donations")
    // console.log("my timerange" + this.state.timerange)
    // console.log("props timerange" + this.props.timerange)
    if (prevProps.timerange !== this.props.timerange) {
      this.updateData();
    }
  }

  async componentDidMount() {
    const data = await Auth.currentSession();
    this.setState(
      {
        jwtToken: data["idToken"]["jwtToken"],
      },
      () => this.updateData()
    );
  }

  convertTimestamp(timestamp) {
    const sections = timestamp.split("-");
    return sections[1] + "-" + sections[2] + "-" + sections[0];
  }

  async updateData() {
    const { jwtToken } = this.state;
    const data = await Auth.currentSession();
    // grab groups from cognito
    const groups = data["idToken"]["payload"]["cognito:groups"];
    // replace the - with a space for every value in the array
    const groupsWithSpaces = groups.map((group) => group.replace(/-/g, " "));

    const res = await queryTransactions(
      jwtToken,
      groupsWithSpaces,
      this.props.timerange,
      -1,
      -1,
      -1,
      [],
      [],
      [],
      [],
      [],
      [],
      5
    );

    let end = 5;
    if (res.length < 5) {
      end = res.length;
    }
    let grossAmountList = [];
    let totalAmountList = [];
    for (let index = 0; index < res.length; index++) {
      console.log("res[index]", res[index]);
      if (res[index].donationFrequency == "monthlyAutomated") {
        res[index].donationFrequency = "Monthly Donation";
      }
      if (res[index].donationFrequency == "monthlySignup") {
        res[index].donationFrequency = "New Monthly Donation";
      }
      if (res[index].donationFrequency == "oneTime") {
        res[index].donationFrequency = "One-Time Donation";
      }
      if (res[index].coverFees === false) {
        res[index].coverFees = "No";
      } else {
        res[index].coverFees = "Yes";
      }
      if (res[index].state === "SUCCEEDED") {
        res[index].state = "Success";
      } else if (res[index].state === "PENDING") {
        res[index].state = "Pending";
      } else if (res[index].state === "REFUNDED") {
        res[index].state = "Refunded";
      } else if (res[index].state === "PARTIAL_REFUND") {
        res[index].state = "Partial Refund";
      } else if (res[index].state === "FULL_REFUND") {
        res[index].state = "Full Refund";
      } else if (res[index].state.includes("DISPUTED")) {
        res[index].state = "Disputed";
      }
      // if (
      //   res[index].state !== "Success" &&
      //   res[index].state !== "Pending" &&
      //   !res[index].state.includes("Refund")
      // )
      else {
        console.log("res[index].state", res[index].state);
        res[index].state = "Failed";
      }
      // console.log({ res })
      //paymentinstrument id holds the totalamountlist variable
      res[index].totalAmount = (res[index].amount - res[index].fees).toFixed(2);
      //      totalAmountList.push((res[index].amount - res[index].fees).toFixed(2))
      if (res[index].coverFees === "Yes") {
        //grossAmount holds the gross amount
        res[index].grossAmount = res[index].totalAmount;
        //     grossAmountList.push(totalAmountList[index])
      } else {
        res[index].grossAmount = res[index].amount;
        //    grossAmountList.push(res[index].amount)
      }
    }
    this.setState(
      {
        donationList: res,
        start: 0,
        end: end,
        total: res.length,
        totalAmountList: totalAmountList,
        grossAmountList: grossAmountList,
      },
      () => {
        // Call the readiness callback after state is updated
        if (this.props.onDataReady) {
          this.props.onDataReady();
        }
      }
    );
  }

  headers1 = [
    { label: "Date", key: "timestamp" },
    { label: ".NGO Donation ID", key: "transactionID" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Fund", key: "fund" },
    { label: "Category", key: "category" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "State", key: "region" },
    { label: "Zip", key: "Zip" },
    { label: "Category", key: "Category" },
    { label: "Phone", key: "phone" },
    { label: "Status", key: "state" },
    { label: "Fee Covered", key: "coverFees" },
    { label: "Donation Frequency", key: "donationFrequency" },
    { label: "Fee", key: "fees" },
    { label: "Total Amount Donated", key: "totalAmount" },
    { label: "Gross Donation", key: "grossAmount" },
  ];

  render() {
    const { donationList, start, end, total } = this.state;
    const headers1 = this.headers1;

    let next = "btn bg-white border-gray-100 hover:border-gray-300 text-light";
    let prev = "btn bg-white border-gray-100 hover:border-gray-300 text-light";
    if (end === total) {
      next = "btn bg-white border-gray-100 text-gray-300 cursor-not-allowed";
    }
    if (start === 0) {
      prev = "btn bg-white border-gray-100 text-gray-300 cursor-not-allowed";
    }

    let emptyRows = [];
    for (var i = 0; i < 10 - (end - start); i++) {
      emptyRows.push(
        <tr>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>
                filler
              </div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>
                filler
              </div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>
                filler
              </div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>
                filler
              </div>
            </div>
          </td>
          <td className="p-2">
            <div className="items-center">
              <div className="text-gray-800" style={{ visibility: "hidden" }}>
                filler
              </div>
            </div>
          </td>
          <td className="p-2">
            <div className="text-center" style={{ visibility: "hidden" }}>
              filler
            </div>
          </td>
          <td className="p-2">
            <div
              className="text-center text-green-500"
              style={{ visibility: "hidden" }}
            >
              filler
            </div>
          </td>
          <td className="p-2">
            <div className="text-center" style={{ visibility: "hidden" }}>
              filler
            </div>
          </td>
          <td className="p-2">
            <div
              className="text-light-blue-500 text-center"
              style={{ visibility: "hidden" }}
            >
              filler
            </div>
          </td>
        </tr>
      );
    }

    return (
      <div className="col-span-full rounded-lg border border-gray-200 bg-white shadow-lg xl:col-span-12">
        <div className="p-3">
          <div className="font-lg px-2 py-2 text-left text-lg font-semibold text-black ">
            Donors
          </div>
          {/* Table */}
          <div className="overflow-x-auto">
            <table className="w-full table-auto">
              {/* Table header */}
              <thead className="rounded-sm bg-gray-50 text-xs uppercase text-gray-400">
                <tr>
                  <th className="p-2">
                    <div className="text-left font-semibold">Date</div>
                  </th>
                  <th className="p-2">
                    <div className="text-left font-semibold">Name</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Amount</div>
                  </th>
                  <th className="p-2">
                    <div className="text-center font-semibold">Status</div>
                  </th>
                </tr>
              </thead>
              {/* Table body */}
              <tbody className="divide-y divide-gray-100 text-sm font-medium">
                {donationList.slice(start, 5).map((donation, index) => {
                  //fix data formatting
                  // const unformattedTimestamp = donation.timestamp.split(" ")[0];
                  // const timestamp = this.convertTimestamp(unformattedTimestamp);

                  //convert amount and coverFees to correct types
                  donation.amount = donation.amount.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  });
                  donation.fees = donation.fees.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  });
                  donation.coverFees = String(donation.coverFees);

                  //set color of true/false and the Total amount
                  let feesColor = "text-center text-light-blue-500";
                  if (donation.coverFees === "No") {
                    feesColor = "text-center text-red-400";
                  }
                  if (donation.category == "None") {
                    donation.category = "N/A";
                  }
                  if (donation.category == null) {
                    //before categories was implemented
                    donation.category = "N/A";
                  }

                  let stateColor =
                    "text-center text-green-800 bg-green-100 rounded-full text-xs";
                  //set color for SUCCEEDED (green), PENDING (blue), REFUNDED (yellow), FAILED (red), and CANCELED (red)
                  if (donation.state === "Pending") {
                    stateColor =
                      "text-center text-blue-800 bg-blue-200 rounded-full text-xs";
                  }
                  if (
                    donation.state === "Refunded" ||
                    donation.state === "Partial Refund" ||
                    donation.state === "Full Refund"
                  ) {
                    stateColor =
                      "text-xs text-center text-gray-800 bg-gray-200 rounded-full";
                  }
                  if (
                    donation.state === "Failed" ||
                    donation.state === "Disputed"
                  ) {
                    stateColor =
                      "text-xs text-center text-red-800 bg-red-200 rounded-full";
                  }
                  while (donation.state !== null) {
                    return (
                      <tr>
                        <td className="p-2">
                          <div className="flex items-center">
                            <div className="text-xs text-gray-800">
                              {formatTimestampToLocal(donation.timestamp)}
                            </div>
                          </div>
                        </td>
                        <td className="p-2">
                          <div className="text-left text-xs">
                            {donation.name}
                          </div>
                        </td>

                        <td className="p-2">
                          <div className="text-center text-xs">
                            $
                            {(
                              Math.round(
                                donation.totalAmount * Math.pow(10, 2)
                              ) / Math.pow(10, 2)
                            )
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </div>
                        </td>
                        <div className="flex shrink-0 flex-col items-center">
                          <button className="align-center content-center p-2 text-center ">
                            <div className="w-28 content-center text-center">
                              <div className={stateColor}>
                                &nbsp;&nbsp;{donation.state}&nbsp;&nbsp;
                              </div>
                            </div>
                          </button>
                        </div>
                      </tr>
                    );
                  }
                })}
                {}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default DonationsTable;
